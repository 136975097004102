/* TODO Add site wide styles */
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Roboto+Mono:wght@400;700&display=swap');

body {
    background-color: #323437;
}



.wgt {
    color: #D1D0C5
}

.yt {
    color: #E2B714
}




.error-text {
    color: #E2B714;
    font-family: 'Lexend Deca', sans-serif;
}


.placeholder-Text::placeholder {
    color: #646669;
    margin-left: 4px;
    /* color: red; */
}


.placeholder-Text:focus {
    border-color: #E2B714;
    outline-color: #E2B714;
    outline: 0.15em solid #E2B714
}

.placeholder-Text {
    background-color: #2C2E31;
    box-shadow: none;
    border: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    caret-color: #E2B714;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}




.default_button {
    background-color: #2C2E31;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
    border: 1px solid #d1d0c518;
}


.default_button_noBorder {
    background-color: #2C2E31;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;

}


.default_button:focus {
    border: 2px solid #E2B714;

}

.disabled_default_button {
    background-color: #D1D0C5;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #2C2E31;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}


.disabled_default_button:hover {
    cursor: not-allowed;
}


.noSizeChange{
    resize: none;
}

.default_button:hover {
    color: #2C2E31;
    background-color: #D1D0C5;
    cursor: pointer;

    .buttonIcon {
        color: #2C2E31;
    }
}


.default_button_noBorder:hover {
    color: #2C2E31;
    background-color: #D1D0C5;
    cursor: pointer;

    .buttonIcon {
        color: #2C2E31;
    }
}



.pointer:hover {
    cursor: pointer;
}


.anti-link {
    text-decoration: none;
}



.HFont {
    font-family: 'Lexend Deca', sans-serif;

}

.pFont {
    font-family: 'Roboto Mono', monospace;
}


.cursor:hover {
    cursor: pointer
}



.overflow {
    overflow-wrap: break-word;

    word-break: break-word;

}


.column-holder-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.column-holder {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;

    /* width: 1000px; */
    /* height: 70vh; */
}

.column-holder-stats {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;
    height: 70vh;
    /* width: 1000px; */
    /* height: 70vh; */
}

.stats-holder {
    align-items: center;
}

.column {
    width: 800px;
}

/* <div className="column-holder Gd-nav-padding">
                <div className="column">


                </div>
            </div> */

.click-toStart {
    display: flex;
    justify-content: center;
}



.left-text {
    /* text-align: left; */
    /* margin-left: 10px; */
}

.PT-button-div {
    display: flex;
    gap: 25px;

}

.PT-button-div-run-del {
    display: flex;
    gap: 12px;
}
