.TP-textarea {
    width: 800px;
    height: 400px;
}

.TP-form {
    width: 850px;
}


.TP-Whole-div {
    display: flex;
    justify-content: space-around;

    align-items: center;
    height: 15vh;
}


.TP-Options-div {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #2C2E31;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    /* margin-right: 200px; */
}

.TP-copyText {
    font-size: 2vh;
}


.spacer {
    height: 100px;
}


.textarea-Text:focus {
    border-color: #E2B714;
    outline-color: #E2B714;
    outline: 0.15em solid #E2B714
}

.textarea-Text {
    background-color: #2C2E31;
    box-shadow: none;
    border: none;

    border-radius: 10px;
    color: #D1D0C5;
    caret-color: #E2B714;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}


.TP-next-delete-div {
    display: flex;
    gap: 10px
}


.TP-current-stats {
    display: flex;
    justify-content: space-evenly;
}

.TP-current-stat {
font-size: 3vh;
}

.footer-space {
    height: 12vh;
}


.text-footer {
    position: fixed;
    bottom: 0;
    left: 0; /* You can adjust the left or right property as needed */
    width: 100%; /* To make the div span the full width of the viewport */

    padding: 10px;
    text-align: center;
}
