.PDM-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #323437;
    width: fit-content;
    height: fit-content;
    padding: 20px;
    gap: 15px;
    /* overflow: auto; */
    border-radius: 30px;
}

.PDM-div-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #323437;
    /* /* width: fit-content; */
    width: 800px;
    height: 50vh;
    /* padding: 20px; */
    gap: 10px;
    overflow: auto;
    /* padding: 10px;
    margin-top: 10px; */
}





.PDM-card-delete-row {
    display: flex;
    gap: 15px;
    min-height: 60px;
    /* margin-top: 2px;
    margin-bottom: 3px; */
    /* height: fit-content; */
}
