.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}



.noPoint {
  list-style-type: none;
}



.Nav-nav-div {
  display: flex;
  justify-content: space-around;
  /* width: 100% */
}

.N-navLink {
  font-size: 2.2vh;
}


.N-navLink:hover {
  font-size: 2.5vh;
}


.Nav-test {
  display: flex;
  gap: 5px;
  align-items: center;
}

.Nav-profile-logout-div {
  display: flex;

  align-items: center;
  gap: 10px;
}


.form-div {
  display: flex;
  gap: 10px;
}

.BF {
  font-size: 2.8vh;
}

.SR-div {
  background-color: #323437;
  width: 400px;
  height: 53vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 30px;
  align-items: center;
  /* overflow: auto; */
}
.SR-inner{
    overflow: auto;
}

.userResult{
  margin-top: 5px;
  margin-bottom: 5px;
}
.userLink{
  font-size: 2vh;
}

.userLink:hover{
  color:#E2B714
}
