.PP-cp-pp-div {
    position: relative;
    margin-bottom: 60px;
}

.PP-pp-div {
    background-color: #e2b514b9;
    display: inline-flex;
    padding: 20px;
    border-radius: 100px;
    position: absolute;
    top: 320px;
    left: 30px
}

.PP-lists-div {
    display: flex;
    gap: 30px;

}

.PP-pp {
    width: 100px;
    height: 100px;
    border-radius: 100px;

}

.PP-columnHolder {
    display: flex;
    justify-content: center;
}

.PP-cp-div {
    display: flex;
    justify-content: center;
    border-radius: 20px;
}

.PP-cp {
    width: 100%;
    height: 400px;
    border-radius: 30px;
}


.PP-list-div {
    margin-top: 30px;
    background-color: #323437; /* Set the card background color */
    border: 1px solid #E2B714; /* Add a yellow border */
    border-radius: 10px; /* Add border-radius for rounded corners */
    padding: 10px; /* Add some padding to the card */
    margin-bottom: 10px; /* Add margin between cards */
    box-shadow: 0px 4px 8px  rgba(248, 245, 88, 0.1);
}

.PP-noGraph-div {
    height: 300px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #2C2E31; */

    /* width: 200px;
    height: 100px; */
    background-color: #323437;
    border: 1px solid #323437; /* Border color matches background */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35) inset;
    margin: 50px auto;







    border-radius: 30px;
    font-size: 1.3vh;
    color: #D1D0C5;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8)

}



.PP-username {
    font-size: 3.5vh;
    margin: 5px;
}

#PP-textAreas {
    width: 720px;
    height: 300px;
}


/* .Player-card {
    border: 2px solid #e2b514e5;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 3px 4px 15px #e2b5145b;
} */

.Player-card {
    background-color: #323437; /* Set the card background color */
    border: 1px solid #E2B714; /* Add a yellow border */
    border-radius: 10px; /* Add border-radius for rounded corners */
    padding: 10px; /* Add some padding to the card */
    margin-bottom: 10px; /* Add margin between cards */
    box-shadow: 0px 4px 8px rgba(248, 245, 88, 0.1);
    /* Add a subtle shadow for depth */
  }


.user-delete-div {
    display: flex;
    gap: 10px;
    margin-top: 10px;

}
