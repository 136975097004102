@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Roboto+Mono:wght@400;700&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);

}

#modal-content {
  position: absolute;
  /* background-color:white; */
  /* border-radius: 20px; */
}

.SFP-div{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.SP-title{
    margin-top: 0;
    margin-bottom: 10px;
}

.SF-upload-div{
    display: flex;
    flex-direction: column;
    grid-gap:10px;
    gap:10px;
    background-color: #2C2E31;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
}

.upload-text{
    margin-left: 12px;
    color: #646669;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}

.LFP-div {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.LFP-title{
    margin-top: 0;
    margin-bottom: 10px;
}

.LP-button {
    margin-top: 15px;
}

.PP-cp-pp-div {
    position: relative;
    margin-bottom: 60px;
}

.PP-pp-div {
    background-color: #e2b514b9;
    display: inline-flex;
    padding: 20px;
    border-radius: 100px;
    position: absolute;
    top: 320px;
    left: 30px
}

.PP-lists-div {
    display: flex;
    grid-gap: 30px;
    gap: 30px;

}

.PP-pp {
    width: 100px;
    height: 100px;
    border-radius: 100px;

}

.PP-columnHolder {
    display: flex;
    justify-content: center;
}

.PP-cp-div {
    display: flex;
    justify-content: center;
    border-radius: 20px;
}

.PP-cp {
    width: 100%;
    height: 400px;
    border-radius: 30px;
}


.PP-list-div {
    margin-top: 30px;
    background-color: #323437; /* Set the card background color */
    border: 1px solid #E2B714; /* Add a yellow border */
    border-radius: 10px; /* Add border-radius for rounded corners */
    padding: 10px; /* Add some padding to the card */
    margin-bottom: 10px; /* Add margin between cards */
    box-shadow: 0px 4px 8px  rgba(248, 245, 88, 0.1);
}

.PP-noGraph-div {
    height: 300px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #2C2E31; */

    /* width: 200px;
    height: 100px; */
    background-color: #323437;
    border: 1px solid #323437; /* Border color matches background */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35) inset;
    margin: 50px auto;







    border-radius: 30px;
    font-size: 1.3vh;
    color: #D1D0C5;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8)

}



.PP-username {
    font-size: 3.5vh;
    margin: 5px;
}

#PP-textAreas {
    width: 720px;
    height: 300px;
}


/* .Player-card {
    border: 2px solid #e2b514e5;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 30px;
    border-radius: 30px;
    box-shadow: 3px 4px 15px #e2b5145b;
} */

.Player-card {
    background-color: #323437; /* Set the card background color */
    border: 1px solid #E2B714; /* Add a yellow border */
    border-radius: 10px; /* Add border-radius for rounded corners */
    padding: 10px; /* Add some padding to the card */
    margin-bottom: 10px; /* Add margin between cards */
    box-shadow: 0px 4px 8px rgba(248, 245, 88, 0.1);
    /* Add a subtle shadow for depth */
  }


.user-delete-div {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;

}

.footerLink {
    font-size: 3vh;
    margin: 15px;
}


.footer-div{
 display: flex;
 justify-content: center;
 /* align-items: center; */
}

.default_button_yellow {
    background-color: #2C2E31;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #E2B714;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
}
.default_button_yellow:hover {
    color:#2C2E31;
    background-color: #D1D0C5;
    cursor: pointer;

    .buttonIcon{
        color: #2C2E31;
    }
}


#Save-margin{
    margin: 15px;
}



.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}



.noPoint {
  list-style-type: none;
}



.Nav-nav-div {
  display: flex;
  justify-content: space-around;
  /* width: 100% */
}

.N-navLink {
  font-size: 2.2vh;
}


.N-navLink:hover {
  font-size: 2.5vh;
}


.Nav-test {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

.Nav-profile-logout-div {
  display: flex;

  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}


.form-div {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.BF {
  font-size: 2.8vh;
}

.SR-div {
  background-color: #323437;
  width: 400px;
  height: 53vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 30px;
  align-items: center;
  /* overflow: auto; */
}
.SR-inner{
    overflow: auto;
}

.userResult{
  margin-top: 5px;
  margin-bottom: 5px;
}
.userLink{
  font-size: 2vh;
}

.userLink:hover{
  color:#E2B714
}


.PDM-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #323437;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px;
    grid-gap: 15px;
    gap: 15px;
    /* overflow: auto; */
    border-radius: 30px;
}

.PDM-div-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #323437;
    /* /* width: fit-content; */
    width: 800px;
    height: 50vh;
    /* padding: 20px; */
    grid-gap: 10px;
    gap: 10px;
    overflow: auto;
    /* padding: 10px;
    margin-top: 10px; */
}





.PDM-card-delete-row {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    min-height: 60px;
    /* margin-top: 2px;
    margin-bottom: 3px; */
    /* height: fit-content; */
}

.TF-Form{
    display: flex;
    flex-direction: column;
    background-color: #323437;
    width: 800px;
    height: 53vh;
    border-radius: 30px;
    padding: 10px;
}


.TF-input {
    margin: 15px;
}

#textSize{
    height: 30vh;
}

.TP-textarea {
    width: 800px;
    height: 400px;
}

.TP-form {
    width: 850px;
}


.TP-Whole-div {
    display: flex;
    justify-content: space-around;

    align-items: center;
    height: 15vh;
}


.TP-Options-div {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    background-color: #2C2E31;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    /* margin-right: 200px; */
}

.TP-copyText {
    font-size: 2vh;
}


.spacer {
    height: 100px;
}


.textarea-Text:focus {
    border-color: #E2B714;
    outline-color: #E2B714;
    outline: 0.15em solid #E2B714
}

.textarea-Text {
    background-color: #2C2E31;
    box-shadow: none;
    border: none;

    border-radius: 10px;
    color: #D1D0C5;
    caret-color: #E2B714;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}


.TP-next-delete-div {
    display: flex;
    grid-gap: 10px;
    gap: 10px
}


.TP-current-stats {
    display: flex;
    justify-content: space-evenly;
}

.TP-current-stat {
font-size: 3vh;
}

.footer-space {
    height: 12vh;
}


.text-footer {
    position: fixed;
    bottom: 0;
    left: 0; /* You can adjust the left or right property as needed */
    width: 100%; /* To make the div span the full width of the viewport */

    padding: 10px;
    text-align: center;
}

.S-div-holder{
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: space-around;
}

.div-404{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
}

.LB-buttonHolder{
    display: flex;
    justify-content: center;
    grid-gap: 150px;
    gap: 150px;
    /* width: 25%; */
    /* margin-left: 20px;
    margin-right: 20px; */
}




.listHolderHolder {
    display: flex;
    justify-content: center;
    align-items: center;
}


.listHolder {
    background-color: #2C2E31;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    margin-top: 30px;
    overflow: auto;
    width: 50%;
    border-radius: 20px;
}

.recordLink {
    width: 100%;
    color: #E2B714;
}

.recordLink:hover {
    cursor: pointer;

    .recordText {
        color: #E2B714
    }
}

.recordHolder {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.recordHolderPre {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
}

/* TODO Add site wide styles */

body {
    background-color: #323437;
}



.wgt {
    color: #D1D0C5
}

.yt {
    color: #E2B714
}




.error-text {
    color: #E2B714;
    font-family: 'Lexend Deca', sans-serif;
}


.placeholder-Text::-webkit-input-placeholder {
    color: #646669;
    margin-left: 4px;
    /* color: red; */
}


.placeholder-Text::placeholder {
    color: #646669;
    margin-left: 4px;
    /* color: red; */
}


.placeholder-Text:focus {
    border-color: #E2B714;
    outline-color: #E2B714;
    outline: 0.15em solid #E2B714
}

.placeholder-Text {
    background-color: #2C2E31;
    box-shadow: none;
    border: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    caret-color: #E2B714;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}




.default_button {
    background-color: #2C2E31;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;
    border: 1px solid #d1d0c518;
}


.default_button_noBorder {
    background-color: #2C2E31;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #D1D0C5;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;

}


.default_button:focus {
    border: 2px solid #E2B714;

}

.disabled_default_button {
    background-color: #D1D0C5;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    height: 40px;
    border-radius: 10px;
    color: #2C2E31;
    font-size: 1.8vh;
    font-family: 'Roboto Mono', monospace;
}


.disabled_default_button:hover {
    cursor: not-allowed;
}


.noSizeChange{
    resize: none;
}

.default_button:hover {
    color: #2C2E31;
    background-color: #D1D0C5;
    cursor: pointer;

    .buttonIcon {
        color: #2C2E31;
    }
}


.default_button_noBorder:hover {
    color: #2C2E31;
    background-color: #D1D0C5;
    cursor: pointer;

    .buttonIcon {
        color: #2C2E31;
    }
}



.pointer:hover {
    cursor: pointer;
}


.anti-link {
    text-decoration: none;
}



.HFont {
    font-family: 'Lexend Deca', sans-serif;

}

.pFont {
    font-family: 'Roboto Mono', monospace;
}


.cursor:hover {
    cursor: pointer
}



.overflow {
    overflow-wrap: break-word;

    word-break: break-word;

}


.column-holder-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.column-holder {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;

    /* width: 1000px; */
    /* height: 70vh; */
}

.column-holder-stats {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;
    height: 70vh;
    /* width: 1000px; */
    /* height: 70vh; */
}

.stats-holder {
    align-items: center;
}

.column {
    width: 800px;
}

/* <div className="column-holder Gd-nav-padding">
                <div className="column">


                </div>
            </div> */

.click-toStart {
    display: flex;
    justify-content: center;
}



.left-text {
    /* text-align: left; */
    /* margin-left: 10px; */
}

.PT-button-div {
    display: flex;
    grid-gap: 25px;
    gap: 25px;

}

.PT-button-div-run-del {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
}

